import { apolloClient } from './apollo';
import * as internalAdmin from '@/mutations/internalAdmin';

async function deleteQuery(args) {
  const x = await apolloClient.mutate({
    mutation: internalAdmin.QUERY_DELETE_MUTATION,
    variables: {
      id: args.id,
      userId: args.userId
    },
  })
  .then((data, loading) => {
    if (!loading) {
      return data.data.queryDelete.status;
    }
  })
  .catch((error) => {
    return error;
  });

  return x;
}

export default deleteQuery;
